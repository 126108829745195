import { RoleAllowedToLogInAsClient, TaxSystem } from "@silexpert/core";

export type SubDrawerItem = {
  id: number;
  label: string;
  to: string;
  hasTabCondition?: boolean;
  tab?: string;
  visibility?: boolean;
  count?: number;
  isNew?: boolean;
};

export type SubDrawer = {
  [key: string]: SubDrawerItem[];
};

export type AdminSubDrawerItem = {
  label: string;
  to: string;
  mainSubNavIndex?: number;
  subNav?: {
    label: string;
    to: string;
    visibility?: boolean;
    subNav?: {
      label: string;
      to: string;
      visibility?: boolean;
    }[];
  }[];
};

export type AdminSubDrawer = {
  [key: string]: AdminSubDrawerItem[];
};

export type SubDrawerTab = SubDrawerItem & { isActive?: boolean };

export function useSubDrawersComposable() {
  const subscriptionStore = useSubscriptionStore();
  const societyStore = useSocietyStore();
  const unlockStore = useUnlockedStore();
  const roleComposable = useRolesComposable();
  const accessComposable = useAccessComposable();
  const permissionComposable = usePermissionsComposable();
  const pendingPointsStore = usePendingPointsStore();
  const revisionGuideStore = useRevisionGuideStore();
  const preComptaStore = usePreComptaStore();
  const specificAccountingPlanStore = useSpecificAccountingPlanStore();
  const persoAccountingPlanStore = usePersoAccountingPlanStore();

  const isRestricted = computed(() => accessComposable.isRestricted());

  const havePermission = roleComposable.hasOneOfTheseRoles(RoleAllowedToLogInAsClient);

  const subDrawers: SubDrawer = ref({
    // BANKS
    banks: [
      {
        id: 0,
        label: "Relevés bancaires",
        to: 'banks?tab="bankStatements"',
        hasTabCondition: true,
        tab: "bankStatements",
        count: unlockStore.bankDocument,
      },
      {
        id: 1,
        label: "Espèces",
        to: 'banks?tab="cash"',
        hasTabCondition: true,
        count: unlockStore.cashDocument,
        tab: "cash",
      },
    ],

    // PURCHASE
    purchases: [
      {
        id: 2,
        label: "Factures",
        to: '/purchases?tab="invoices"',
        hasTabCondition: true,
        count: unlockStore.purchaseDocument,
        tab: "invoices",
      },
      ...(societyStore.isAccruals
        ? [
            {
              id: 3,
              label: `Notes de Frais`,
              to: '/purchases?tab="expensenotes"',
              hasTabCondition: true,
              count: unlockStore.personalDocument,
              tab: "expensenotes",
            },
          ]
        : [
            {
              id: 3,
              label: "Notes de Frais",
              to: "/purchases/expense-notes",
              count: unlockStore.personalDocument,
            },
          ]),
      {
        id: 4,
        label: "Fournisseurs",
        to: "/purchases/providers",
      },
      {
        id: 5,
        label: "Grand Livre",
        to: "/purchases/auxiliaryLedger",
        visibility: societyStore.isAccruals,
      },
    ],

    // SALES
    sales: [
      {
        id: 5,
        label: "Factures",
        to: '/sales?tab="Factures"',
        hasTabCondition: true,
        count: unlockStore.saleDocument,
        tab: "Factures",
      },
      {
        id: 6,
        label: "Devis",
        to: '/sales?tab="Devis"',
        hasTabCondition: true,
        tab: "Devis",
      },
      {
        id: 7,
        label: "Brouillons",
        to: '/sales?tab="Brouillons"',
        hasTabCondition: true,
        tab: "Brouillons",
      },
      {
        id: 8,
        label: "Articles",
        to: "/sales/products",
      },
      {
        id: 9,
        label: "Clients",
        to: "/sales/customers",
      },
      {
        id: 10,
        label: "Paiements",
        to: "/sales/payments",
      },
      {
        id: 11,
        label: "Recouvrement",
        to: "/sales/recovery",
        isNew: true,
        visibility: useBrandsComposable().isClementine(),
      },
      {
        id: 12,
        label: "Tickets Z",
        to: "/sales/ztickets",
      },
      {
        id: 13,
        label: "Grand Livre",
        to: "/sales/auxiliaryLedger",
        visibility: societyStore.isAccruals,
      },
    ],

    // Employee
    employees: [
      {
        id: 12,
        label: "Éléments de paie",
        to: '/employees?tab="Éléments de paie"',
        hasTabCondition: true,
        tab: "Éléments de paie",
      },
      {
        id: 13,
        label: "Salariés",
        to: '/employees?tab="Salariés"',
        hasTabCondition: true,
        tab: "Salariés",
      },
    ],

    // LIBRARY
    library: [
      {
        id: 14,
        label: "Généralités",
        to: '/library?tab="Généralités"',
        hasTabCondition: true,
        tab: "Généralités",
      },
      {
        id: 15,
        label: "Entreprise",
        to: `/library?tab="Entreprise"`,
        hasTabCondition: true,
        tab: "Entreprise",
      },
      {
        id: 16,
        label: "Comptabilité",
        to: `/library?tab="Comptabilité"`,
        hasTabCondition: true,
        tab: "Comptabilité",
      },
      {
        id: 17,
        label: "Social",
        to: `/library?tab="Social"`,
        hasTabCondition: true,
        tab: "Social",
      },
      {
        id: 18,
        label: "Juridique",
        to: `/library?tab="Juridique"`,
        hasTabCondition: true,
        tab: "Juridique",
      },
      {
        id: 19,
        label: "Facturation",
        to: `/library?tab="Facturation"`,
        hasTabCondition: true,
        tab: "Facturation",
      },
      {
        id: 20,
        label: "Courrier",
        to: `/library?tab="Courriers"`,
        hasTabCondition: true,
        tab: "Courriers",
      },
      {
        id: 21,
        label: "Relevés bancaires",
        to: `/library?tab="RelevésBancaires"`,
        hasTabCondition: true,
        tab: "RelevésBancaires",
      },
    ],

    // BALANCESHEET
    balancesheets: [
      {
        id: 22,
        label: "À faire",
        to: "/balancesheet",
      },
      {
        id: 23,
        label: "Documents",
        to: "/balancesheet/documents",
      },
    ],

    // SETTINGS
    settings: [
      {
        id: 24,
        label: "Informations",
        to: "/settings/informations",
      },
      {
        id: 25,
        label: "Banques",
        to: "/settings/banks",
        visibility:
          !isRestricted.value &&
          (permissionComposable.hasOneOfThesePermissions([EPermissions.COMPTA]) || havePermission),
      },
      {
        id: 26,
        label: "Annotations",
        to: "/settings/annotations",
        visibility:
          !isRestricted.value &&
          (permissionComposable.hasOneOfThesePermissions([EPermissions.COMPTA]) || havePermission),
      },
      {
        id: 27,
        label: "Achats",
        to: "/settings/purchase-mail",
        visibility: !isRestricted.value,
      },
      {
        id: 28,
        label: "Ventes",
        to: "/settings/billing",
      },
      {
        id: 29,
        label: "Intégrations",
        to: "/settings/ecommerce",
        visibility: !isRestricted.value,
      },
      {
        id: 30,
        label: "Exports",
        to: "/settings/exports",
        visibility: !isRestricted.value,
      },
      {
        id: 33,
        label: "Plan comptable",
        to: "/settings/chart-accounts",
        visibility:
          useBrandsComposable().isComptalib() &&
          !isRestricted.value &&
          (permissionComposable.hasOneOfThesePermissions([EPermissions.COMPTA]) || havePermission),
      },
      {
        id: 32,
        label: "Abonnement",
        to: "/settings/subscription",
        visibility: subscriptionStore.canSubscribe,
      },
      {
        id: 31,
        label: "Accès",
        to: "/settings/access",
        visibility: !isRestricted.value,
      },
    ],

    // PARTNER
    partner: [
      {
        id: 32,
        label: "Partenaires",
        to: "/partners",
      },
    ],

    partnerAccountant: [
      {
        id: 37,
        label: "Expertise comptable",
        to: "/partner-accountants",
      },
    ],

    balanceSheetAccountant: [
      {
        id: 41,
        label: "À faire",
        to: `/balancesheet?tab=todo`,
        hasTabCondition: true,
        tab: "todo",
      },
      {
        id: 42,
        label: "Documents",
        to: `/balancesheet?tab=files`,
        hasTabCondition: true,
        tab: "files",
      },
      {
        id: 43,
        label: "Bilan",
        to: `/balancesheet?tab=customer`,
        hasTabCondition: true,
        tab: "customer",
        visibility: roleComposable.hasOneOfTheseRoles(RoleAllowedToLogInAsClient),
      },
    ],

    // SHOP
    shop: [
      {
        id: 33,
        label: "Services",
        to: "/shop/services",
      },
    ],

    // VAT
    vat: [
      {
        id: 38,
        label: "Déclarations de TVA",
        to: "/vat",
      },
      ...(havePermission && !useBrandsComposable().isComptalib()
        ? [
            {
              id: 36,
              label: "Formulaire DAS2",
              to: "/vat/das2",
            },
          ]
        : []),
      {
        id: 37,
        label: "Documents annexes",
        to: `/vat/annex`,
      },
    ],
  }).value;

  const adminSubDrawers: AdminSubDrawer = ref({
    society: [
      {
        label: "Configuration",
        to: "config",
        mainSubNavIndex: 1,
        subNav: [
          {
            label: "Pré-compta",
            to: useBuildRouteFromQueryPropertiesComposable(
              defaultPreComptaQueryProperties.value,
              {
                ...preComptaStore.queryProperties,
              },
              `/admin/societies/${societyStore.society?.id}/config/precompta`,
            ),
          },
          {
            label: "Informations",
            to: `/admin/societies/${societyStore.society?.id}/config/information`,
          },
          {
            label: "Utilisateurs",
            to: `/admin/societies/${societyStore.society?.id}/config/users`,
            tab: "Utilisateurs",
            hasTabCondition: true,
          },
          {
            label: "Factures",
            to: `/admin/societies/${societyStore.society?.id}/config/invoices`,
          },
          {
            label: "Comptes bancaires",
            to: `/admin/societies/${societyStore.society?.id}/config/bank`,
          },
          {
            label: "Plan comptables",
            to: useBuildRouteFromQueryPropertiesComposable(
              defaultSpecificAccountingPlanQueryProperties.value,
              {
                ...specificAccountingPlanStore.queryProperties,
                page: 1,
                limit: 25,
              },
              `/admin/societies/${societyStore.society?.id}/config/accountplan/specific`,
            ),
            subNav: [
              {
                label: "Spécifique",
                to: useBuildRouteFromQueryPropertiesComposable(
                  defaultSpecificAccountingPlanQueryProperties.value,
                  {
                    ...specificAccountingPlanStore.queryProperties,
                    page: 1,
                    limit: 25,
                  },
                  `/admin/societies/${societyStore.society?.id}/config/accountplan/specific`,
                ),
              },
              {
                label: "Personnalisé",
                to: useBuildRouteFromQueryPropertiesComposable(
                  defaultSpecificAccountingPlanQueryProperties.value,
                  {
                    ...persoAccountingPlanStore.queryProperties,
                    page: 1,
                    limit: 25,
                  },
                  `/admin/societies/${societyStore.society?.id}/config/accountplan/perso`,
                ),
              },
            ],
          },
          {
            label: "Exportation",
            to: `/admin/societies/${societyStore.society?.id}/config/export`,
          },
        ],
      },
      {
        label: "Bilan",
        to: "accounting",
        mainSubNavIndex: 0,
        subNav: [
          {
            label: "Comptes",
            to: `/admin/societies/${societyStore.society?.id}/accounting/revisionaccount`,
          },
          {
            label: "Clients",
            to: `/admin/societies/${societyStore.society?.id}/accounting/customers`,
            visibility: havePermission,
          },
          {
            label: "Fournisseurs",
            to: `/admin/societies/${societyStore.society?.id}/accounting/providers`,
            visibility: havePermission,
          },
          {
            label: "Points en suspens",
            to: useBuildRouteFromQueryPropertiesComposable(
              defaultPendingPointsQueryProperties.value,
              {
                ...pendingPointsStore.queryProperties,
              },
              `/admin/societies/${societyStore.society?.id}/accounting/pendingpoints`,
            ),
          },
          {
            label: "Guide de révision",
            to: useBuildRouteFromQueryPropertiesComposable(
              defaultRevisionGuideQueryProperties.value,
              {
                ...revisionGuideStore.queryProperties,
              },
              `/admin/societies/${societyStore.society?.id}/accounting/revisionguide`,
            ),
          },
          {
            label: "Documents",
            to: `/admin/societies/${societyStore.society?.id}/accounting/documents`,
          },
        ],
      },
      {
        label: "Télédéclarations",
        to: "declaration",
        mainSubNavIndex: 0,
        subNav: [
          {
            label: "Liasse",
            to: `/admin/societies/${societyStore.society?.id}/declaration/balancesheet`,
          },
          ...(societyStore.isNotSubjectToVat === false
            ? [
                {
                  label: "Déclaration TVA",
                  to: `/admin/societies/${societyStore.society?.id}/declaration/vat`,
                },
              ]
            : []),
          {
            label: "Formulaire DAS2",
            to: `/admin/societies/${societyStore.society?.id}/declaration/das2`,
          },
          ...(societyStore.society?.idTaxSystem === TaxSystem.IS
            ? [
                {
                  label: "Acompte IS",
                  to: `/admin/societies/${societyStore.society?.id}/declaration/companyTaxAdvances`,
                },
              ]
            : []),
          {
            label: "Documents annexes",
            to: `/admin/societies/${societyStore.society?.id}/declaration/annex`,
          },
        ],
      },
    ],
  }).value;

  return { subDrawers, adminSubDrawers };
}
